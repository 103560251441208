import React from "react";

const NodejsIco = (props) => {
    return (
        <svg viewBox="0 0 512 314"  {...props} >
        <g>
	<path fill="#5E5E5E" d="M253.11,313.09c-1.73,0-3.35-0.46-4.85-1.27l-15.37-9.13c-2.31-1.27-1.16-1.73-0.46-1.96
		c3.12-1.04,3.7-1.27,6.93-3.12c0.35-0.23,0.81-0.12,1.16,0.12l11.79,7.05c0.46,0.23,1.04,0.23,1.39,0l46.11-26.7
		c0.46-0.23,0.69-0.69,0.69-1.27v-53.28c0-0.58-0.23-1.04-0.69-1.27l-46.11-26.58c-0.46-0.23-1.04-0.23-1.39,0l-46.11,26.58
		c-0.46,0.23-0.69,0.81-0.69,1.27v53.28c0,0.46,0.23,1.04,0.69,1.27l12.6,7.28c6.82,3.47,11.1-0.58,11.1-4.62v-52.59
		c0-0.69,0.58-1.39,1.39-1.39h5.89c0.69,0,1.39,0.58,1.39,1.39v52.59c0,9.13-4.97,14.45-13.64,14.45c-2.66,0-4.74,0-10.63-2.89
		l-12.14-6.93c-3-1.73-4.85-4.97-4.85-8.44v-53.28c0-3.47,1.85-6.7,4.85-8.44l46.11-26.7c2.89-1.62,6.82-1.62,9.71,0l46.11,26.7
		c3,1.73,4.85,4.97,4.85,8.44v53.28c0,3.47-1.85,6.7-4.85,8.44l-46.11,26.7C256.46,312.75,254.73,313.09,253.11,313.09z
		 M267.33,276.46c-20.23,0-24.39-9.25-24.39-17.11c0-0.69,0.58-1.39,1.39-1.39h6.01c0.69,0,1.27,0.46,1.27,1.16
		c0.92,6.13,3.58,9.13,15.83,9.13c9.71,0,13.87-2.2,13.87-7.4c0-3-1.16-5.2-16.3-6.7c-12.6-1.27-20.46-4.05-20.46-14.1
		c0-9.36,7.86-14.91,21.03-14.91c14.79,0,22.07,5.09,23,16.18c0,0.35-0.12,0.69-0.35,1.04c-0.23,0.23-0.58,0.46-0.92,0.46h-6.01
		c-0.58,0-1.16-0.46-1.27-1.04c-1.39-6.36-4.97-8.44-14.45-8.44c-10.63,0-11.9,3.7-11.9,6.47c0,3.35,1.5,4.39,15.83,6.24
		c14.22,1.85,20.92,4.51,20.92,14.45C290.33,270.68,282,276.46,267.33,276.46z"/>
	<path d="M110.03,104.71c0-2.08-1.16-4.05-3-5.09l-49-28.2c-0.81-0.46-1.73-0.69-2.66-0.81H54.9c-0.92,0-1.85,0.35-2.66,0.81
		l-49,28.2c-1.85,1.04-3,3-3,5.09l0.12,75.82c0,1.04,0.58,2.08,1.5,2.54c0.92,0.58,2.08,0.58,2.89,0l29.13-16.64
		c1.85-1.04,3-3,3-5.09v-35.48c0-2.08,1.16-4.05,3-5.09l12.37-7.17c0.92-0.58,1.96-0.81,3-0.81s2.08,0.23,2.89,0.81l12.37,7.17
		c1.85,1.04,3,3,3,5.09v35.48c0,2.08,1.16,4.05,3,5.09l29.13,16.64c0.92,0.58,2.08,0.58,3,0c0.92-0.46,1.5-1.5,1.5-2.54
		L110.03,104.71L110.03,104.71z"/>
	<path d="M345.57,0.35c-0.92-0.46-2.08-0.46-2.89,0c-0.92,0.58-1.5,1.5-1.5,2.54v75.12c0,0.69-0.35,1.39-1.04,1.85
		c-0.69,0.35-1.39,0.35-2.08,0l-12.25-7.05c-1.85-1.04-4.05-1.04-5.89,0l-49,28.32c-1.85,1.04-3,3-3,5.09v56.52
		c0,2.08,1.16,4.05,3,5.09l49,28.32c1.85,1.04,4.05,1.04,5.89,0l49-28.32c1.85-1.04,3-3,3-5.09V21.84c0-2.2-1.16-4.16-3-5.2
		L345.57,0.35L345.57,0.35z M341.06,144.12c0,0.58-0.23,1.04-0.69,1.27l-16.76,9.71c-0.46,0.23-1.04,0.23-1.5,0l-16.76-9.71
		c-0.46-0.23-0.69-0.81-0.69-1.27v-19.42c0-0.58,0.23-1.04,0.69-1.27l16.76-9.71c0.46-0.23,1.04-0.23,1.5,0l16.76,9.71
		c0.46,0.23,0.69,0.81,0.69,1.27V144.12L341.06,144.12z"/>
	<g transform="translate(401.047404, 70.501129)">
		<path d="M107.6,53.74c1.85-1.04,2.89-3,2.89-5.09V34.9c0-2.08-1.16-4.05-2.89-5.09L58.94,1.62c-1.85-1.04-4.05-1.04-5.89,0
			l-49,28.32c-1.85,1.04-3,3-3,5.09v56.52c0,2.08,1.16,4.05,3,5.09l48.66,27.74c1.85,1.04,4.05,1.04,5.78,0l29.47-16.41
			c0.92-0.46,1.5-1.5,1.5-2.54c0-1.04-0.58-2.08-1.5-2.54L38.72,74.55c-0.92-0.58-1.5-1.5-1.5-2.54V54.32c0-1.04,0.58-2.08,1.5-2.54
			l15.37-8.78c0.92-0.58,2.08-0.58,3,0l15.37,8.78c0.92,0.58,1.5,1.5,1.5,2.54v13.87c0,1.04,0.58,2.08,1.5,2.54
			c0.92,0.58,2.08,0.58,3,0L107.6,53.74L107.6,53.74z"/>
		<path fill="#5E5E5E" d="M55.25,51.08c0.35-0.23,0.81-0.23,1.16,0l9.36,5.43c0.35,0.23,0.58,0.58,0.58,1.04v10.86
			c0,0.46-0.23,0.81-0.58,1.04l-9.36,5.43c-0.35,0.23-0.81,0.23-1.16,0l-9.36-5.43c-0.35-0.23-0.58-0.58-0.58-1.04V57.56
			c0-0.46,0.23-0.81,0.58-1.04L55.25,51.08L55.25,51.08z"/>
	</g>
	<g transform="translate(134.067720, 70.501129)">
		<g>
			
				<image width="110" height="125" id="path-2_00000160174243520144635860000005277314326090100123_" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAB9CAYAAABDGVbOAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABI9JREFUeNrsnVdW40AQRadrb+Q9
sDlyzvywOHPgHJgZJ3WorOcPDqiN7O7rW6onjFz+JLvt7Ows1m3/+PgomeZZsgPLCrDMCVomeGVO
wDIBLHMElgFgmTu0qAALgMWEVwAsJsACYDEBunxSu7u739AWCxfsXMIrHoEt3wDQKbhNwDzC8wKw
eAcGgM7A9UBD+TQENwoM9imD4wQGgArgJIF5hacBsGSANkf7RHa8t7dnGqDnYF+RAOZlITMDLNLQ
PCxiRoBFGhjsk4FXtIDBPl6ARRMY7OMD2HTn/f39BffkYV8fvNIKTGrisK8N4NY7HBwcVBkGgPoA
yxSw2sllgBcJ4MqGw8PDxQgk2KcDrywD4wKE5kUW4PeXo6OjhYRhsE8OHv2qVzb3KVNjm8a3jTUH
zmLzx3rOObA+ry/bNAyDfbw34rRI0z4LCzzZRyMlknsM5bPROG3DMthn+cJZKZUW9qF5YTrGWRgG
+5iaEwvDYF9Hc+LJMNjXaJy2RYgOzM2JtkWIDoEDOKJD8ACO6BA4gCM6BAngiA5BAziiQ+EB59Ei
RIfgARzRIXAAR3QIHsDnHh3W5rgshmW2jzYNZjEsq300NcEshmWzb6VUbgOI6OAnOmw85eXBPkSH
zlNeEvYhOjCBW35QafsQHZiNm7Kt1z5EB0HjWmxDdPBhH/XYhuhgbx+N2IboYAeQWuEgOvgon9QL
B9HB1j4asQ3Rwc4+krAN0UHePuKEg+igZx+NlkJEBxv7SMs2RAdegCQJB9FBrnySRClEdJC3j7Rs
Q3TgtY+sbUN06LOPLcchOuhGB9IshYgOfPDIohQiOjCdq/TQWSI6DJzy8tBZIjp0nPKyKoWIDh3/
ZuWpFCI6lPZSGelYh+hQkeMsj3WIDpXNSUTb5hodyGPjgehQca7Sa+MRwT7LskvRSqEn+yxLMk39
UtRjXfboQDWvzsjHuqzRgVpenYgOfpqeyctlZLItU3Sg2geN2llmjQ7U8qCIDn6iQ9X1KlubF0QH
hQBe+yqsLZ+IDjpj398dHx9Xf6JV7fbWnzn2IbHPqe0WY+/v738RroPXOpFIMKIC/IL2a1wvPM5F
iQLYAt7P+A+0FXDe7fMCWBvg29vb9KcSTwGMYJ8WYGnD1gGrAsdlX6ZjnZZ9r6+vW9lUvztFonxm
amZqLZoamwLWDK6nfCI61I+9vLw0seh+O+0oQESHRTe0IXCRmxcv0eH5+bl7/Vn+fcSjfZ6jwwgw
VnCbACI6/H97enpiW2/2S7t5aF48AuaEJgJOqnxGPdY9Pj6KrLH4pUxry2e26PDw8CC6tmoXER4B
GMm2+/t7lTVVvXR3xOalZZ9a0NTBebVvdB93d3fq62jzaQdrAEaMDre3t2brZwquBaCn6HBzc2O+
bi7AcZdPyWOdB2iuwHmwb9t9rq+vXa2VO3CjALltu7q6crlGbsF5aF4uLy/dro9rcFb2eQYWCtwy
QCn7Li4uwqxHKHC1AFubl/Pz83DrEBIcZ/k8OzsLuQZhwY3ad3p6Gnru4cG1AowOLB24fwGug3dy
cpJqrp8CDADdB/nRqUej0AAAAABJRU5ErkJggg==" transform="matrix(1 0 0 1 -0.0677 0.4989)">
			</image>
		</g>
		
			<image width="110" height="125" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAAB9CAYAAABDGVbOAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAlBJREFUeNrs3V1Ow0AMRtG6e+uq
uzh4rgSCKK0zn+dkBSknd7Ajfm634dfj8fia+LlqJ7Tn81nggmubAFi7oU0BrF3R0gHvN1fkAFMQ
MusDFwpY0DIBC1omHrhQwIKWCQguFLCgZeKBCwUsaJmABS0T0LvK0AerfFEy6wMXCljQMgELWiYe
uFDAgpYJaB0IfTBLbZn1gQsFLGiZgAUtEw9cKGBBy8SzDoQWd1db5lXQMgcUR6Xi1Na5DihOcWrr
qk1xoWjLwKkt8KiEdrw2R6Xi1NZZm+IUp7bO2i4rDtr5y1EZWNslcGpT3La1tQ8nansPmuKsA2rr
rK2tOGiGE7V1walNcWrrglNb4FQJ7XO1OSoVp7bO2hQXivYROLVZB9TWBae2wOEEWl9tjsrdi1Nb
b22K27k4tfXXdro4aPY4tXUdlWq7Dk1xuw0naru2NsXtVJzarq/tcHHQrANq64JTm+LU1gWntsCp
Etp6tTkqQ9H+hFOb4URtXXBqU5zauuDUFrgOQFu/NkfllOLUllGb4kLRXuDUFnhUQsuqzVE5YThR
XU5tL8WteHOuAwu4+tav7Vc4gOFwu+Ot/O3j3ze2I+AIuN0AVx/W/JpVINqpBdz6EFrc1PpSHsi3
3uQEwC3h0vGSjn9/IRZcNmDasOU/fYDLxUtcbS654dUAwQUCpr5IWOKmrwJMfvuzxM+ceH0WWtwV
9aU/LMve/KcBwQXiTTiaIz7AuwHBBQJOGYQiP8QZQHCBeJPWjvgPcgQQXCDgtCV/3BuLnwAnvpn5
FmAAYsx2KAbMqIAAAAAASUVORK5CYII=" transform="matrix(1 0 0 1 -0.0677 0.4989)">
		</image>
		
			<image width="112" height="136" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHAAAACICAYAAAA/IW8/AAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAx9JREFUeNrs21lu4kAUhWFXyVtk
GayGZTBP22EelkG3lTZSR0GYgF333PrPU5Qn8Knv5lYERUEIIYQQQgghhBCZBG9vqN/v377/bjAY
BK8Flp6LQ6Cj8rwqDDmp81himUNxjFBH5XlTWKIOgXLFeVIYchXnpcTIuESgbHEeFJaIQ6B0ceoK
I+oQKF+cssKAOO0Sy9yLY4Q6Kk9RYUlxCHRVnJrCQHnaJZYUl+kI9V6cisKS4jISmFt5CgpLinMs
kOLsKwwUp11ipDxHI5Ty9BRGqnG2xKBQSyECxe/BUfHyShDoRmFUvLySJxd5FhqdhYYR6lkgCu0r
RKD4QhMVThlBoFuFjQpEIQJRmGoLZSO1vZVG6y+QMEJdj9KXC0QhAlGYaolhobE3qRAonrdOCQrT
K0RgzgJRmF4hAnMXiMK0CvmOvHiJjFBGKApTKkQgAlGYUiECO86nD3i0fsIIAl0rbKVAFCIQhakL
RKHoNYJrRbcHnREqPkqj9RNGEOhaYScFohCBKEy1hbKRtjutEMg9EIUpFUbLL44wQt0vNEHhRTJK
EcgSg0KbChGIQBSmVIhABKIwpUIEit8No+XTRURGKKP094edEYpAFKZUiEDxhSZaPl3keRAomM1m
Y7tAFDYrD4Fi2W63RQihWK/XwewWykb6uLwqq9Xqv85KHo1GcT+VZ15g7gofqZMqMMcSmxTHCDWY
3W73pervorJcLhvhklnXPSusi6vStDgEGivv1eLkBHpT+I46BCbMfr+//51bLBZvA5L7l5Wywrq8
TxSHQPHiZAUqKayLa6s82QKtl3g4HIrb7dZqcYzQFsvrojh5gdYU1sXN5/NOnykCP1RcivLkBaZU
eDwe7z/PZrNkzxGBb5SXsjg3ArtUaKk4BL6Q0+n0ddpDKKbTqalD7+bTX20orIurYq04BD7J+Xw2
XZw7gZ9SWBdXZTKZmH8+7j5A+9sS1YpjhP7L5XK5Lyjj8VjuQLv8CHtThXV5isVlLbAuTr08twIf
Kbxer/efR6ORi/eejcC6PC/FuRdYpdfr3RUOh0O+skYI+XD+CDAAnC2xBSrWXfYAAAAASUVORK5C
YII=" transform="matrix(1 0 0 1 -0.0677 -8.5011)">
		</image>
	</g>
</g>
        </svg>
    );
};
export default NodejsIco;
