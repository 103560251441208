import React from "react";

const DeployIco = (props) => {
    return (
        <svg viewBox="0 0 256.72 257.35"  {...props} >
            <g>
                <path d="M201.04,224.11c2.73-9.54,7.95-16.77,17.04-20.61c5.71-2.41,11.61-2.68,17.64-1.31c11.67,2.65,21.79,14.08,20.95,28.56
		c-0.69,11.88-9.19,22.81-20.96,25.63c-14.87,3.57-25.26-2.64-34.81-21.16c-1.64-0.16-3.58-0.49-5.52-0.51
		c-7.33-0.07-14.67,0.04-22-0.05c-12.96-0.15-18.23-5.47-18.25-18.29c-0.01-7.63,0-15.25,0-23.42c3.61,0,6.44,0,9.76,0
		c0.2,2.14,0.53,4.07,0.55,6c0.08,6.5,0.08,13.01,0.04,19.51c-0.02,3.7,1.85,5.72,5.41,5.79c8.33,0.17,16.67,0.13,25,0.14
		C197.37,224.41,198.85,224.24,201.04,224.11z"/>
                <path d="M55.77,224.32c10.25,0,20.06,0.08,29.86-0.04c3.53-0.04,5.48-2.02,5.46-5.74c-0.03-6.33-0.01-12.67,0.03-19
		c0.02-1.97,0.2-3.93,0.33-6.48c3.19,0,6.02,0,9.35,0c0.22,1.68,0.59,3.27,0.6,4.87c0.06,7,0.12,14,0,21
		c-0.15,8.97-4.34,14.3-13.3,15.19c-8.42,0.84-16.96,0.42-25.45,0.58c-2.3,0.05-4.6,0.21-7.27,0.34
		c-2.98,9.72-8.58,17.09-18.25,20.54c-5.81,2.07-11.84,2.08-17.71,0.36C6.89,252.26-0.48,241,0.02,226.77
		c0.41-11.75,9.33-22.07,21.77-24.76c7.64-1.65,14.79-0.23,21.48,4.08C49.86,210.33,52.93,216.86,55.77,224.32z"/>
                <path d="M201.13,32.94c-10.61,0-20.57-0.14-30.52,0.08c-3.18,0.07-5.11,2.21-5.11,5.64c0,5.16,0.01,10.32-0.05,15.49
		c-0.02,1.79-0.21,3.59-0.35,5.93c-3.21,0-6.04,0-9.22,0c-0.27-1.45-0.68-2.7-0.7-3.97c-0.07-6.16-0.17-12.33,0.01-18.48
		c0.23-8.19,4.32-13.49,12.44-14.35c8.57-0.91,17.27-0.54,25.91-0.76c2.46-0.06,4.91-0.22,7.67-0.35
		c2.88-9.22,7.96-16.4,16.94-20.01c6.04-2.43,12.38-2.74,18.63-1.01c12.6,3.5,20.18,14.7,19.81,28.86
		c-0.31,11.87-9.22,22.46-21.3,25.11c-7.64,1.68-14.88,0.52-21.59-3.62C206.93,47.36,203.3,40.95,201.13,32.94z"/>
                <path d="M55.72,22.16c1.96,0.12,3.93,0.35,5.89,0.36c7.5,0.05,15-0.11,22.5,0.06c11.94,0.27,17.3,5.84,17.34,17.72
		c0.02,6.45,0,12.89,0,19.84c-3.7,0-6.53,0-10.34,0c0-3.84,0-7.27,0-10.7c0-3,0.03-6-0.01-9c-0.08-5.86-1.58-7.53-7.36-7.6
		c-8.66-0.1-17.33,0.01-26,0.07c-0.63,0-1.26,0.33-2.3,0.61c-2.65,8.79-7.48,16.14-16.39,20c-6.35,2.75-12.92,3.03-19.57,1.2
		C7.31,51.37-0.32,40.14,0.01,26.11C0.3,14.21,9.2,3.65,21.27,0.9C36.21-2.51,46.42,3.64,55.72,22.16z"/>
                <path d="M83.08,99.46c13.88,7.53,27.37,14.85,40.98,22.23c0,21.1,0,41.84,0,63.36c-1.78-0.66-3.16-1-4.36-1.65
		c-11.27-6.1-22.59-12.12-33.68-18.52c-1.81-1.05-3.71-3.8-3.74-5.8c-0.28-18.82-0.16-37.64-0.12-56.46
		C82.15,101.87,82.58,101.12,83.08,99.46z"/>
                <path d="M174.07,99.16c0.2,2.98,0.42,4.72,0.43,6.47c0.03,16.99-0.1,33.98,0.09,50.97c0.05,4.59-1.55,7.33-5.67,9.45
		c-9.62,4.94-19,10.35-28.5,15.52c-2.29,1.25-4.66,2.32-7.74,3.84c0-21.69,0-42.52,0-63.79C146.13,114.31,159.63,107,174.07,99.16z"
                />
                <path d="M169.38,92.36c-8.33,4.61-15.86,8.8-23.41,12.95c-4.38,2.41-8.64,5.11-13.24,6.98c-2.37,0.97-5.88,1.46-7.96,0.4
		c-12.15-6.21-24.03-12.94-36-19.52c-0.25-0.14-0.38-0.52-1.58-1.83c0.9-0.39,1.83-0.71,2.69-1.17
		c11.26-6.13,22.56-12.2,33.75-18.46c3.27-1.83,6.08-1.82,9.35,0c11.19,6.26,22.49,12.32,33.73,18.49
		C167.4,90.59,167.96,91.2,169.38,92.36z"/>
                <path fill="#FFFFFF" d="M237.25,229.24c0,7.75-0.67,8.4-8.59,8.38c-7.65-0.01-8.25-0.65-8.23-8.73c0.02-7.49,0.66-8.11,8.33-8.11
		C236.65,220.78,237.26,221.39,237.25,229.24z"/>
                <path fill="#FFFFFF" d="M36.15,229.33c-0.01,7.71-0.64,8.32-8.66,8.3c-7.57-0.02-8.2-0.7-8.17-8.81c0.03-7.39,0.7-8.03,8.43-8.03
		C35.58,220.79,36.16,221.38,36.15,229.33z"/>
                <path fill="#FFFFFF" d="M237.25,27.99c0,7.84-0.62,8.46-8.5,8.46c-7.76-0.01-8.34-0.6-8.32-8.62c0.01-7.56,0.66-8.2,8.24-8.21
		C236.62,19.6,237.25,20.21,237.25,27.99z"/>
                <path fill="#FFFFFF" d="M36.15,27.85c0.01,8-0.57,8.6-8.34,8.6c-7.86,0-8.49-0.62-8.48-8.48c0.01-7.65,0.67-8.33,8.11-8.36
		C35.54,19.59,36.14,20.16,36.15,27.85z"/>
            </g>

        </svg>
    );
};
export default DeployIco;
