import React from "react";

const ReactIco = (props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" fill="inherit" r="1.417" />
      <path  d="m12 24c-3.248 0-5-6.183-5-12s1.752-12 5-12 5 6.183 5 12-1.752 12-5 12zm0-22.5c-1.652 0-3.5 4.49-3.5 10.5s1.848 10.5 3.5 10.5 3.5-4.49 3.5-10.5-1.848-10.5-3.5-10.5z" />
      <path d="m18.874 19.507c-2.685 0-6.304-1.268-9.45-3.07-4.215-2.415-7.37-5.585-8.038-8.076-.238-.889-.166-1.672.214-2.325 1.674-2.879 7.935-1.361 12.977 1.526 4.215 2.415 7.37 5.585 8.038 8.076.238.89.167 1.672-.213 2.325-.631 1.083-1.91 1.544-3.528 1.544zm-13.757-13.504c-1.126 0-1.918.267-2.22.787-.172.295-.193.693-.062 1.183.467 1.741 2.916 4.631 7.335 7.163 5.21 2.985 10.069 3.557 10.934 2.074.172-.295.192-.693.062-1.183-.467-1.741-2.916-4.631-7.335-7.163-3.387-1.94-6.625-2.861-8.714-2.861z" />
      <path  d="m5.126 19.507c-1.619 0-2.897-.461-3.527-1.543-.38-.653-.452-1.436-.213-2.325.667-2.491 3.823-5.661 8.038-8.076 5.041-2.889 11.302-4.405 12.976-1.527.38.653.452 1.436.213 2.325-.667 2.491-3.823 5.661-8.038 8.076-3.144 1.802-6.764 3.07-9.449 3.07zm13.757-13.504c-2.089 0-5.327.92-8.713 2.861-4.419 2.532-6.868 5.421-7.335 7.163-.131.49-.11.888.062 1.183.865 1.483 5.724.913 10.934-2.074 4.419-2.532 6.868-5.421 7.335-7.163.131-.49.11-.888-.062-1.183-.303-.519-1.095-.787-2.221-.787z" />
      <path  d="m12 14c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.5c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z" />
    </svg>
  );
};
export default ReactIco;
