import React from "react";

const TypescriptIco = (props) => {
    return (
        <svg viewBox="0 0 256.1 256.03"  {...props} >
            <path d="M256.09,128.24c0,33.67,0.01,67.33,0,101c-0.01,16.38-10.44,26.78-26.87,26.79c-67.51,0.01-135.03,0.01-202.54,0
	c-16.1,0-26.66-10.5-26.66-26.49C0,161.87,0,94.2,0.01,26.54c0-16,10.57-26.53,26.62-26.53C94.31,0,161.99,0,229.67,0.01
	c15.75,0,26.4,10.58,26.41,26.23C256.11,60.24,256.09,94.24,256.09,128.24z M158.49,205.81c0,5.44,0.3,11.72-0.11,17.96
	c-0.27,4.13,1.34,6.03,5.04,7.18c16.3,5.08,32.83,6.15,49.08,0.79c5.88-1.94,11.9-5.79,15.95-10.43
	c11.52-13.16,8.47-34.81-6.21-44.89c-7.19-4.94-15.58-8.1-23.34-12.24c-3.65-1.94-7.47-3.83-10.53-6.52
	c-5.83-5.13-4.78-12.5,2.22-15.9c3.19-1.55,6.93-2.35,10.5-2.65c10.18-0.87,19.62,2.09,29.02,7.24c0-7.04-0.2-13.35,0.09-19.63
	c0.14-3.07-1.27-4.16-3.8-4.67c-4.56-0.91-9.09-2.2-13.69-2.59c-14.44-1.23-28.73-0.83-41.4,7.53
	c-16.75,11.06-18.24,35.92-2.59,48.5c5.35,4.3,11.93,7.1,18.04,10.42c5.24,2.85,10.8,5.15,15.85,8.29
	c4.17,2.59,6.73,6.64,5.42,11.89c-1.19,4.76-4.98,7.27-9.41,7.84c-5.54,0.71-11.47,1.56-16.76,0.32
	C173.97,212.4,166.49,208.79,158.49,205.81z M116.45,233.3c0-30.62,0-60.8,0-91.55c11.05,0,21.44,0,31.7,0c0-6.98,0-13.38,0-19.96
	c-29.81,0-59.21,0-88.81,0c0,6.83,0,13.37,0,20.47c10.71,0,21.1,0,31.87,0c0,30.63,0,60.72,0,91.04
	C99.54,233.3,107.49,233.3,116.45,233.3z"/>

        </svg>
    );
};
export default TypescriptIco;
